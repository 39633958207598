
import { HttpClient, HttpParams } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { apiPaths } from '../api-services-paths';

@Injectable({
  providedIn: 'root'
})
export class RequirementService {

  protected contractsDataSubject = new Subject<any>();

  constructor(
    private http: HttpClient,
  ) { }


  public manageRequirement (manageRequirementModel: any): Observable<any> {
    return this.http.put<any>(apiPaths.requirementPath.managementChange,manageRequirementModel )
  }

  // public saveRequirement(saveRequirementModel: SaveRequirementModel): Observable<any> {
  //   console.log("Data:: ", saveRequirementModel)
  //   return this.http.post<any>( apiPaths.requirementPath.saveRequirement, saveRequirementModel )
  // }

  //New fuction for Adminsitrador Temporal
  // public saveRequirementTemp(saveTempDataAdmin:  SaveTempDataAdmin): Observable<any> {
  //   console.log("Data:: ",  saveTempDataAdmin)
  //   return this.http.post<any>( apiPaths.requirementPath.saveRequirement,saveTempDataAdmin )
  // }

  public findByIdContractAndReceptor(requirementParam: any): Observable<any> {
    let params = new HttpParams()
      .set('email', requirementParam.email)
      .set('type', requirementParam.type)
    return this.http.get<any>( apiPaths.requirementPath.findByIdContractAndReceptorRequirement, {params})
  }
  
  

}
