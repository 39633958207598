import { Injectable } from '@angular/core';
import { Observable, Subject, catchError, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { apiPaths } from '../api-services-paths';
import { LoggerService } from '../../logger-service/logger.service';
import { ApiResponse } from '../api-services.interface';
import { UserRol } from 'src/app/models';

interface UserRolResponse extends ApiResponse<UserRol> { };
@Injectable({
  providedIn: 'root'
})
export class UserService {
  userData: UserRolResponse | undefined;
  userDataSubject = new Subject<any>();

  constructor(private readonly http: HttpClient, private readonly logger: LoggerService) {
    // Cargar los datos del usuario al iniciar el servicio
    this.loadUserData();
    //console.log("Datos del usuario almacenados en el servicio:", this.userData);
  }

  /**
   * Carga los datos del usuario desde el backend y los almacena localmente.
   */
  private loadUserData(): void {
    this.getUserDataFromBackend()
      .pipe(
        catchError((err) => {
          this.logger.error('Error al obtener los datos del usuario:' + JSON.stringify(err));
          return [];
        })
      ).subscribe((res) => {
        this.logger.log(`GET ${apiPaths.getUser}`)
        this.userData = res;
        this.userDataSubject.next(res);
      });
  }

  /**
   * Obtener los datos del usuario autenticado desde el backend.
   * @returns {Observable<any>} Observable que contiene los datos del usuario autenticado.
   */
  private getUserDataFromBackend(): Observable<UserRolResponse> {
    return this.http.get<UserRolResponse>(apiPaths.getUser);
  }


  /**
   * Obtener los datos del usuario almacenados localmente en el servicio.
   * @returns {UserRolResponse | undefined} Datos del usuario almacenados en el servicio.
   */
  getCurrentUserData(): UserRolResponse | undefined {
    //console.log("estos son los datos de geCurrentUserData: ",this.userData);
    return this.userData;
  }

  /**
   * Método para suscribirse a los datos del usuario almacenados en el Subject.
   * @returns {Observable<UserRolResponse>} Observable que contiene los datos del usuario.
   */
  getUserDataSubject(): Observable<UserRolResponse> {
    return this.userDataSubject.asObservable();
  }

  getUser(): Observable<UserRol> {
    this.logger.log(`GET ${apiPaths.getUser}`)
    return this.http.get<UserRolResponse>(apiPaths.getUser).pipe(
      map((res) => res.data as UserRol));
  }
}
