import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { BcModalComponent } from '@bancolombia/design-system-web/bc-modal';
import { BcOffCanvasComponent } from '@bancolombia/design-system-web/bc-off-canvas';
import { PopoutComponent } from '../../popout/popout.component';
import { BcDialogService } from '@bancolombia/design-system-web/bc-services';
import { BcAlertComponent } from '@bancolombia/design-system-web/bc-alert';
import { RequirementService } from 'src/app/services/api-services/requirement-services/requirement.service';
import { RequirementTypeEnum } from 'src/app/enums/reporte-novedad.enum';
import { UserGraphServices } from 'src/app/services/api-services/graph-services/user-graph.services';
import { RequirementStatusEnum } from 'src/app/enums/reporte-estado.enum';
import { ActivatedRoute } from '@angular/router';
import { ContratosService } from 'src/app/services/api-services/contratos-services/contratos.service';
import { EstadoServicesService } from 'src/app/services/estado-services/estado-service';
import { RequestCreateRequirement } from 'src/app/services/api-services/requirement-services/requirement-services.interface';

@Component({
  selector: 'app-novedad-administrador',
  templateUrl: './novedad-administrador.component.html',
  styleUrls: ['./novedad-administrador.component.css']
})
export class NovedadAdministradorComponent implements OnInit {


  ngOnInit(): void {

    this.route.paramMap.subscribe(params => {
      const contractId = params.get('id');
      if (contractId !== null) {
        this.contratosService.getContractByIdContract(contractId).subscribe(data => {
          this.contractDetail = data.data;
        });
      }
    });

    this.currentUser = JSON.parse(sessionStorage.getItem('userCurrentGraph') || '{}');


  }

  selectedOption: string | null = null;
  contractDetail: any = { selectedOption: null };
  public userGraphData: any;
  public userGrapFilter: any[] = [];
  public formMeetAdminInputs: any = {};
  public inputAdministratorObservation: string = "";
  public showModal = true;
  public valuesTemp: any = {};
  protected currentUser: any;
  @Input('contractDetail') _contract!: any;

  SaberSi: number | null = 10;
  ReporteNo: number | null = 10;

  dateSelector = new FormControl();
  @Input() textButton!: string;
  @Input() contrato: any;

  @ViewChild('offCanvasComponent', { static: true })
  bcOffCanvasComponent!: BcOffCanvasComponent;

  bcModalComponent: BcModalComponent | any;
  PopOutComponent: PopoutComponent | any;

  tipoOffcanvas = '';
  backdrop = '';
  alturaHorizontal = '';

  backdrops = [
    {
      label: 'Activo',
      value: '',
    },
    {
      label: 'Apagado',
      value: 'off',
    },
  ];

  alturasHorizontal = [
    {
      label: '90vh',
      value: '90vh',
    },
    {
      label: '50vh',
      value: '50vh',
    },
    {
      label: '40vh',
      value: '40vh',
    },
  ];


  constructor(private formBuilder: FormBuilder, private dialogService: BcDialogService
    , private requirementService: RequirementService, private userGraphServices: UserGraphServices,
    private route: ActivatedRoute, private contratosService: ContratosService, private estadoService: EstadoServicesService
  ) {
    this.getAllUserGraph()
  }

  onOptionSelected(event: any) {

    const value = event.target.value;
    if (value === "") {
      this.selectedOption = null; // Establece en null si no se selecciona ninguna opción
      this.alertAba("error", "No se seleccionó ninguna opción", "Error");
      return; // Detiene la función aquí si no se selecciona ninguna opción.
    }
    this.selectedOption = value;
    this.contractDetail.selectedOption = value; // Asignar la opción al objeto contractDetail
    this.estadoService.setEstadoSeleccionado(value);
  }


  /* Abre el offcanvas */
  openOffCanvas() {
    this.bcOffCanvasComponent.openOffCanvas();
  }

  /* Cierra el offcanvas */
  closeOffCanvas() {
    // Reinicia los valores de tus variables
    this.selectedOption = null;
    this.contractDetail = { selectedOption: null };
    this.formMeetAdminInputs = {};
    this.inputAdministratorObservation = "";
    this.SaberSi = null;
    this.ReporteNo = null;
    this.userGrapFilter = [];
    this.bcOffCanvasComponent.closeOffCanvas(null);

  }

  resetRadioValues() {
    this.SaberSi = null;
    this.formMeetAdminInputs = {}; // Reinicia los valores del formulario
    this.userGrapFilter = []; // Limpia la lista de sugerencias de usuarios
  }

  resetRadioValuesNo() {
    this.ReporteNo = null;
    this.formMeetAdminInputs = {}; // Reinicia los valores del formulario
    this.userGrapFilter = []; // Limpia la lista de sugerencias de usuarios
  }


  private getAllUserGraph() {
    this.userGraphServices.getAllUserGraph().subscribe(
      {
        next: (success) => this.userGraphData = success.value,
        //error: (error) => this.alertAba("error", "No se pudo obtner la lista de usuario", "Error")
      }
    )
  }

  public onNoveltySend(requerimentType: any, requirementStatus: any) {

    const savedRequirement: RequestCreateRequirement = {
      idContract: this.contrato.idContract,
      description: "No se tiene conocimiento del administrador de contrato",
      requirementType: requerimentType
    }
    // const saveData: SaveRequirementModel = {
    //   emailReceptor: this.formMeetAdminInputs.administatorEmail,
    //   emailReques: this.contractDetail.admin.email,
    //   idContract: this.contrato.idContract,
    //   observation: this.formMeetAdminInputs.administratorObservation,
    //   initialDate: this.formMeetAdminInputs.adminsitratorInitialDate,
    //   finalDate: this.formMeetAdminInputs.adminsitratosFinalDate,
    //   requirementType: requerimentType,
    //   requirementStatus: requirementStatus,
    // };
    // console.log("traje esto: ", saveData);

    if (this.SaberSi == 2) {
      console.log(this.formMeetAdminInputs.administratorObservation)
      savedRequirement.description = this.formMeetAdminInputs.administratorObservation;
      savedRequirement.newAdminEmail = this.formMeetAdminInputs.administatorEmail;
    }

    // if (this.SaberSi == 3) {
    //   // Si no sabes quién es el administrador, no asignes fechas
    //   saveData.initialDate = new Date();
    //   saveData.finalDate = new Date();
    //   saveData.emailReceptor = this.contractDetail.negotiator.email;
    //   saveData.emailReques = this.contractDetail.admin.email; // Verifica si es null o undefined y asigna una cadena vacía si lo es
    //   saveData.observation = "No se tiene conocimiento del administrador de contrato";
    // }

    // if (this.SaberSi == 2) {
    //   // Si no sabes quién es el administrador, no asignes fechas
    //   saveData.initialDate = new Date();
    //   saveData.observation = this.formMeetAdminInputs.administratorObservation;
    // }


    this.requirementService.createRequirement(savedRequirement).subscribe(
      {
        next: (res) => {
          if (res.responseCode !== 200) {
            this.alertAba("error", res.message, "error");
            return;
          }
          this.alertAba("success", "La novedad de cambio de Administrador fue reportada", "Correcto")
          this.closeOffCanvas();
        },
        error: (err) => {
          this.alertAba("error", err.message, "error");
        },
      }
    )

    //Function for Adminsitrador Nuevo

  }

  public get requirementTypeEnum(): typeof RequirementTypeEnum {
    return RequirementTypeEnum;
  }

  public get requirementStatusEnum(): typeof RequirementStatusEnum {

    return RequirementStatusEnum;

  }

  /**
   * Busca en la informacion el dato mas cercano
   * @param data 
   * @returns []
   */
  public keyUpSearchUse(data: any) {
    const regex = new RegExp(`^${data.target.value}`, "i");
    const suggestions = this.userGraphData.sort().filter((v: any) => regex.test(v.displayName));
    this.userGrapFilter = suggestions;
  }



  public selectSearchedUser(data: any) {
    this.formMeetAdminInputs = {
      administratorName: data.displayName,
      administatorEmail: data.mail,
      administratorObservation: "La novedad de cambio de Adminsitrador fue reportada. El operador de abastecimiento se encargará de actualizar el delegado del contrato.",
      adminsitratorInitialDate: null, // Asegúrate de usar el nombre correcto aquí
      adminsitratosFinalDate: null
    }

    this.userGrapFilter = [];
  }




  private alertAba(type: String, menssage: String, tittle: string): void {
    const dialogRef = this.dialogService.open(BcAlertComponent, {
      id: tittle,
      type: type,
      text: menssage,
      timeout: 7000,
    });

    dialogRef.onResult().subscribe(
      (closed) => { },
      (dismissed) => { },
      () => { }
    );
  }





}