<div class="bc-p-3 bc-bg-white">
    <!-- Observaciones -->
    <div class="bc-row bc-w-100">
        <div class="bc-column">
            <span><b>Observaciones</b></span>
            <p>{{novedad.requirement.description}}</p>
        </div>
    </div>

    <!-- Fecha de radicacion -->
    <div class="bc-row bc-w-100 bc-margin-top-3">
        <div class="bc-column">
            <span><b>Fecha de radicaci&oacute;n</b></span>
            <p>{{ novedad.status[0].date | date:'dd-MM-yyyy' }}</p>
        </div>
    </div>
    <!-- Condicional para mostrar información adicional -->
    <div *ngIf="novedad.requirement.requirementType === 'REPORTE DE NOVEDAD DE ESTADO DE CONTRATO'">
        <div class="bc-row bc-w-100 bc-margin-top-3">
            <div class="bc-column">
                <span><b>Cambio de estado del contrato</b></span>
                <div class="bc-flex bc-direction-row bc-justify-content-start bc-align-self-center bc-margin-top-1">
                    <app-badge-estado [clase]="'contrato'" [estadoContrato]="'Publicado'" [tipo]="'only'">
                    </app-badge-estado>
                    <!-- arrow-long-right -->
                    <bc-icon size="md" name="arrow-long-right" [ariaHidden]="false" ariaLabel="name"></bc-icon>
                    <app-badge-estado [clase]="'contrato'" [estadoContrato]="'Cerrado'" [tipo]="'only'">
                    </app-badge-estado>
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="novedad.requirement.requirementType == 'REPORTE NOVEDAD CON ADMINISTRADOR' || novedad.requirement.requirementType == 'REPORTE NOVEDAD SIN ADMINISTRADOR'">
        <div class="bc-row bc-w-100 bc-margin-top-3">
            <div class="bc-column bc-bg-white bc-w-100">
                <span><b>Cambio de estado de administrador</b></span>
                <div class="bc-flex bc-justify-content-start bc-direction-row bc-align-self-center bc-margin-top-1">
                    <!-- Current admin -->
                    <div
                        class="bc-flex bc-direction-column bc-margin-right-2 bc-bg-brand-sequential-N-50 bc-p-3 bc-border-radius-2-full">
                        <span>{{novedad.requirement.applicant.name}}</span>
                        <span
                            class="bc-opensans-font-style-1-semibold">{{novedad.requirement.applicant.email}}</span>
                    </div>
                    <!-- arrow-long-right -->
                    <bc-icon size="md" name="arrow-long-right" [ariaHidden]="false" ariaLabel="name"></bc-icon>
                    <!-- New Admin? or -->
                    <div *ngIf="!novedad.adminChangeRequest">
                        <div
                            class="bc-flex bc-direction-column bc-margin-left-2 bc-bg-brand-sequential-N-50 bc-p-3 bc-border-radius-2-full bc-h-100">
                            <bc-icon size="md" name="user" [ariaHidden]="false" ariaLabel="name"></bc-icon>
                            <span class="bc-opensans-font-style-1-semibold">Desconocido</span>
                        </div>
                    </div>
                    <div *ngIf="novedad.adminChangeRequest">
                        <div
                            class="bc-flex bc-direction-column bc-margin-left-2 bc-bg-brand-sequential-N-50 bc-p-3 bc-border-radius-2-full">
                            <span>{{novedad.adminChangeRequest.receptor.name}}</span>
                            <span
                                class="bc-opensans-font-style-1-semibold">{{novedad.adminChangeRequest.receptor.email}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <!-- Cambio de estado  -->

    <!-- Info a cambiar (admin o estado) -->


    <!-- <div class="bc-container">
        
        <div class="bc-row">
            <div class="bc-flex bc-flex-direction-row">
                <span><b>Observaciones</b></span>
            </div>
            <div class="bc-col-6">
                <div class="bc-container">
                    <div class="bc-col-2">
                        <bc-icon  size="2xl"  name="calendar-day" [ariaHidden]="false" ariaLabel="name"></bc-icon>
                    </div>
                    <div class="bc-col-8">
                        <b>
                            <span>Fecha de inicio contrato</span>
                        </b>
                        
                    </div>
                    <div class="bc-col-4">
                        <div >
                            <p>{{ novedad.initialDate | date:'dd-MM-yyyy' }}</p>
                        </div>
                    </div>

                </div>
            </div>
            
            <div class="bc-col-6">
                <div class="bc-container">
                    <div class="bc-col-2">
                        <bc-icon  size="2xl"  name="calendar-exclamation" [ariaHidden]="false" ariaLabel="name"></bc-icon>
                    </div>
                    <div class="bc-col-8">
                        <b>
                            <span>Fecha de fin</span>
                        </b>
                    </div>
                    <div class="bc-col-4">
                        <div >
                            <p>{{ novedad.finalDate | date:'dd-MM-yyyy' }}</p>
                        </div>
                    </div>

                </div>
            </div>

        </div>

    </div>
    <br> -->

    <!-- Parte 2 -->

    <!-- <div class="bc-container">

        <div class="bc-row">
            <div class="bc-col-6">
                <div class="bc-container">
                    <div class="bc-col-2">
                        <bc-icon  size="2xl"  name="value" [ariaHidden]="false" ariaLabel="name"></bc-icon>
                    </div>
                    <div class="bc-col-8">
                        <b>
                            <span>
                                Estado del requerimiento
                            </span>
                        </b>
                    </div>
                    <div class="bc-col-4">
                        <div class="bc-flex bc-flex-direction-row bc-align-items-center">
                            <app-badge-estado
                                    [clase]="'novedad'"
                                    [estadoAdmin]="novedad.status"
                                    [tipo]="'only'">
                                    </app-badge-estado>
                        </div>
                    </div>

                </div>
            </div>
            
            <div class="bc-col-6">
                <div class="bc-container">
                    <div class="bc-col-2">
                        <bc-icon  size="2xl"  name="document" [ariaHidden]="false" ariaLabel="name"></bc-icon>
                    </div>
                    <div class="bc-col-8">
                        <b>
                            <span>
                                Tipo de novedad
                            </span>
                        </b>
                    </div>
                    <div class="bc-col-7">
                        <div >
                            <p>{{novedad.typeRequirement}}</p>
                        </div>
                    </div>

                </div>
            </div>

        </div>

    </div>

    <div class="bc-margin-top-3">
        <hr>
    </div> -->
    <!--Tabla-->
    <div>
        <bc-table-container class="bc-w-100" [dataTable]="dataTable">
            <bc-table-content>
                <div class="bc-align-left bc-p-3">
                </div>
                <table caption="tabla" bc-table class="bc-p-2">
                    <thead>
                        <tr>
                            <th scope="row" bc-cell type="total">Fecha</th>
                            <th scope="row" bc-cell type="total">Estado novedades</th>
                            <th scope="row" bc-cell type="total">Observaciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <!-- Put ng-for here -->
                        <ng-container *ngFor="let status of novedad.status">
                            <tr>
                                <td>{{ status.date | date: 'MMMM d, y, h:mm:ss a'}}</td>
                                <td>
                                    <!--app-badge-estado [clase]="'novedad'" [estadoNovedad]="item.estado.nombre"
                                        [tipo]="'simple'"></app-badge-estado-->
                                    <app-badge-estado [clase]="'novedad'" [estadoAdmin]="status.name" [tipo]="'simple'">
                                    </app-badge-estado>
                                </td>
                                <td>
                                    <!--app-badge-estado [clase]="'novedad'" [estadoNovedad]="item.estado.nombre"
                                        [tipo]="'simple'"></app-badge-estado-->
                                    <span>
                                        {{status.comment}}
                                    </span>

                                </td>
                                <!--td>Se realiza el cambio de estado en la herramienta y se actualizan los
                                    correspondientes aplicativos.</td-->
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </bc-table-content>
        </bc-table-container>
    </div>
</div>