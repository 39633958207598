import { Component } from '@angular/core';
import { formatearFecha } from 'src/app/components/utils/formato-fecha.componente';
import { NovedadEstado } from 'src/app/models/novedad.interface';

import { NovedadesService } from 'src/app/services/api-services/novedades-services/novedades.service';
import { RequirementService } from 'src/app/services/api-services/requirement-services/requirement.service';
import { AccordionColumn } from '@bancolombia/design-system-web/bc-accordion';

import { UserService } from 'src/app/services/api-services/user-services/user.service';



@Component({
  selector: 'app-novedades-negociador',
  templateUrl: './novedades-negociador.component.html',
  styleUrls: ['./novedades-negociador.component.css']
})
export class NovedadesNegociadorComponent {


  tittle = "En esta página encuentras las novedades que te han reportado";
  fecha: any;
  protected currentUser: any;
  public requirement: any[] = [];
  public novedad: any[] = [];
  requirementnov: any[] = [];
  pendingRequirements: any[] = [];
  completedRequirements: any[] = [];

  uniqueContractIds: Set<string> = new Set<string>();
  userData: any = {};
  changeStateRequierement = new Object();
  dataLoaded: boolean = false;


  changeStateRequirement = {
    idRequiremet: 'requirement.requirementId',
    status: 'requirement.contractId.status'
  }


  ngOnInit() {
    this.mostrarHora();
    this.currentUser = JSON.parse(sessionStorage.getItem('userCurrentGraph') || '{}');
    this.userData = this.userService.getCurrentUserData();
    this.loadData();

  }

  constructor(
    private novedadesServices: NovedadesService,
    private requirementService: RequirementService,
    private userService: UserService
  ) {
    this.novedadesServices.searchNovedadesByAdmin()
  }

  private loadData() {

    const requirementParamnNov: any = {
      email: this.userData.data.email,
      type: "RECEPTOR"
    };

    // Utiliza un solo servicio para obtener datos
    this.requirementService.findRequirementsForReceptorOrApplicant(requirementParamnNov).subscribe({
      next: (res) => {
        console.log(res);
        const requirements = [...res];
        
        requirements.forEach((r: any) => {
          if(r.lastStatus === 'FINALIZADO' || r.lastStatus === 'RECHAZADO') {
            this.completedRequirements.push(r);
            return;
          }
          this.pendingRequirements.push(r);

        })
        console.log(this.completedRequirements);

        this.dataLoaded = true;
      }
    });
  }



  mostrarHora() {
    this.fecha = formatearFecha(new Date());
    setInterval(() => {
      this.fecha = formatearFecha(new Date());
    }, 1000);
  }

  getNovedades(): NovedadEstado[] {
    return this.novedadesServices.resultados
  }

  columns: Array<AccordionColumn & { field?: string }> = [
    { value: 'Solicitud nueva asignación de Contrato' },
  ];

  columnsForRequirement(requirement: any): Array<AccordionColumn> {
    return [
      { value: requirement.lastStatus },
    ];
  }

  /**
  Obtiene las columnas para un panel de acordeón para una novedad.
  @param {NovedadEstado} novedadEstado - La novedad y su estado actual.
  @returns {Array<AccordionColumn>} - Las columnas del panel de acordeón.
  */
  getAccordionColumns(): Array<AccordionColumn> {
    const cols: Array<AccordionColumn> = [
      //{value: `Contrato ${novedadEstado.contrato.idContrato}`, alignLeft: true},
      //{value: `${novedadEstado.tipoNovedad.nombre}`},
      //{value: `${novedadEstado.historialEstados[0].estado.nombre}`},
    ]

    return cols
  }

  getAccordionColumnsSol(): Array<AccordionColumn> {
    const cols: Array<AccordionColumn> = [
      //{value: `Contrato ${novedadEstado.contrato.idContrato}`, alignLeft: true},
      //{value: `${novedadEstado.tipoNovedad.nombre}`},
      //{value: `${novedadEstado.historialEstados[0].estado.nombre}`},
    ]

    return cols
  }












}
