import { Component, Input } from '@angular/core';

export interface MenuOptions {
  id?: string,
  link?: string,
  label?: string,
  icon?: string
}

@Component({
  selector: 'app-float-menu-aba',
  templateUrl: './float-menu-aba.component.html',
  styleUrls: ['./float-menu-aba.component.css']
})
export class FloatMenuAbaComponent {
  @Input() options: MenuOptions[] = [];
  @Input() iconDescription?: string;
  @Input() description?: string
  @Input() title: string = "Opciones";
  menuVisible = false;

  toggleMenu() {
    this.menuVisible = !this.menuVisible;
  }
}
