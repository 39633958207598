import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, Subject } from 'rxjs';
import { apiPaths } from '../api-services-paths';
import { manageRequirement, RequirementHistory } from 'src/app/models/index';
import { RequestCreateRequirement, RequestUpdateRequirement, RequirementResponse, RequirementStatusHistoryResponse, UserRequirementType } from './requirement-services.interface';
import { LoggerService } from '../../logger-service/logger.service';

@Injectable({
  providedIn: 'root'
})
export class RequirementService {
  protected contractsDataSubject = new Subject<any>();

  constructor(
    private readonly http: HttpClient,
    private readonly logger: LoggerService
  ) { }

  createRequirement(request: RequestCreateRequirement): Observable<RequirementResponse> {
    this.logger.log("BodyRequest: " + JSON.stringify(request));
    return this.http.post<RequirementResponse>(apiPaths.requirementPath.saveRequirement, request);
  }

  updateHistoryRequirement(request: RequestUpdateRequirement): Observable<any> {
    return this.http.post<any>(apiPaths.requirementPath.updateHistoryRequirement, request);
  }

  manageRequirement(manageRequirementModel: manageRequirement): Observable<any> {
    return this.http.put<any>(apiPaths.requirementPath.managementChange, manageRequirementModel)
  }

  // saveRequirement(saveRequirementModel: SaveRequirementModel): Observable<any> {
  //   console.log("Data:: ", saveRequirementModel)
  //   return this.http.post<any>(apiPaths.requirementPath.saveRequirement, saveRequirementModel)
  // }

  //New fuction for Adminsitrador Temporal
  // saveRequirementTemp(saveTempDataAdmin: SaveTempDataAdmin): Observable<any> {
  //   console.log("Data:: ", saveTempDataAdmin)
  //   return this.http.post<any>(apiPaths.requirementPath.saveRequirement, saveTempDataAdmin)
  // }

  findByIdContractAndReceptor(requirementParam: any): Observable<any> {
    let params = new HttpParams()
      .set('email', requirementParam.email)
      .set('type', requirementParam.type)
    return this.http.get<any>(apiPaths.requirementPath.findByIdContractAndReceptorRequirement, { params })
  }

  findRequirementsForReceptorOrApplicant(request: { email: string, type: UserRequirementType }): Observable<RequirementHistory[]> {
    let params = new HttpParams()
      .set('email', request.email)
      .set('type', request.type)

    this.logger.log(`GET ${apiPaths.requirementPath.findByIdContractAndReceptorRequirement}`)
    return this.http.get<RequirementStatusHistoryResponse>(apiPaths.requirementPath.findByIdContractAndReceptorRequirement, { params }).pipe(
      map((res) => res.data as RequirementHistory[]))
  }

  getAllAdminChangeRequest(request: { email: string }): Observable<any> {
    let params = new HttpParams()
      .set('email', request.email)
    return this.http.get<any>(apiPaths.requirementPath.getAllAdminChangeRequest, { params }).pipe(
      map((res) => res.data))

  }

  updateAdminChangeRequest(request: { adminChangeId: string; status: string }): Observable<any> {
    return this.http.post<any>(apiPaths.requirementPath.updateAdminChangeRequest, request);
  }
}
