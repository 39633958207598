import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-badge-estado',
  templateUrl: './badge-estado.component.html'
})
export class BadgeEstadoComponent implements OnInit {

  /**
   * Parametro para denotar la clase del estado.
   * @type {('contrato' | 'novedad')}
   */
  @Input() clase!: 'contrato' | 'novedad';

  /**
   * Parametro para denotar el estado del contrato.
   * @type {("Publicado" | "Vencido" | "Borrador" | "Modificacion del borrador" | "Congelado" | "Pendiente | "Cerrado")}
  */
  @Input() estadoContrato?: "Publicado" | "Vencido" | "Borrador" | "Modificacion del borrador" | "Congelado" | "Pendiente" | "Cerrado" = "Pendiente";

  @Input() estadoNovedad?: "PENDIENTE" | "CERRADO" | "ACTIVO";

  @Input() estadoAdmin?: "PENDIENTE" | "EN PROCESO" | "RECHAZADO" | "COMPLETADO" | "FINALIZADO";


  /**
   * Parametro para especificar el uso de icono. Por defecto es `false`.
   * @type {boolean}
  */
  @Input() editIcon: boolean = false;

  /**
   * Parametro para denotar el tipo de badge a usar.
   * @type {('icon-right' | 'only' | 'simple')}
  */
  @Input() tipo!: 'icon-right' | 'only' | 'simple';
  @Output() iconRightClicked: EventEmitter<void> = new EventEmitter<void>();



  texto: string = "Publicado";
  colorBadge: 'alba' | 'andino' | 'caribe' | 'macondo' | 'flamenco' | 'orquidea' | 'neutro' | 'black' | 'white' | "#fdda24" | any = "#fdda24";
  icon: string = 'modify';

  ngOnInit() {
    const requirementStatusColor = {
      'PENDIENTE': 'macondo',
      'EN PROCESO': 'caribe',
      'RECHAZADO': 'flamenco',
      'COMPLETADO': 'neutro',
      'FINALIZADO': 'andino',
    }
    const colorDict = {
      // Colores Para Contratos
      'Publicado': 'andino',
      'Vencido': 'alba',
      'Pendiente': 'macondo',
      'Borrador': 'neutro',
      'Modificacion del borrador': 'orquidea',
      'Congelado': 'white',
      // Colores Para Admin
      'En Proceso': '#fdda24',
      'EN PROCESO': '#fdda24',
      'completado': 'neutro',
      'Finalizado': 'neutro',
      'Rechazado': 'neutro',
      'Cerrado': 'neutro',
      'PENDIENTE': 'alba',
      'CERRADO': 'neutro',
      'ACTIVO': 'andino',
      'RECHAZADO': 'alba',
      'TRAMITADO': 'andino',
      'ACEPTADO': 'andino'
    }

    if (this.tipo) {
      if (this.clase == 'contrato') {
        this.colorBadge = colorDict[this.estadoContrato!];
        this.texto = this.estadoContrato!; // Establece el texto en función del estado del contrato
      } else if (this.clase == 'novedad') {
        this.colorBadge = requirementStatusColor[this.estadoAdmin!];
        this.texto = this.estadoAdmin!; // Establece el texto en función del estado de la novedad/administración
      }
    }
  }

  onIconRightClick() {
    // Emite el evento cuando el usuario hace clic en el icono derecho
    this.iconRightClicked.emit();
  }

}
