<div class="float-menu-aba">
  <button class="bc-d-flex bc-align-self-center" (click)="toggleMenu()">
    <bc-icon name="menu"></bc-icon>
    <b class="bc-m-auto">{{ title }}</b>
  </button>
  <div class="float-menu-aba__menu" [class.float-menu-aba__menu-content--active]="menuVisible">
    <div *ngIf="description" class="bc-d-flex bc-gap-2 bc-padding-2 bc-border-bottom bc-align-self-center">
      <bc-icon  *ngIf="iconDescription" size="md" [name]="iconDescription"></bc-icon>
      <span>{{ description }}</span>
    </div>
    <ul class="float-menu-aba__menu-content" *ngIf="options.length">
      <li class="float-menu-aba__menu-content-item" *ngFor="let option of options">
        <a 
          class="bc-d-flex bc-padding-1 bc-gap-2 bc-text-decoration-none" 
          [href]="option.link"
          target="_blank"
        >
          <bc-icon *ngIf="option.icon" size="sm" [name]="option.icon"></bc-icon> 
          <span>{{ option.label }}</span>
        </a>
      </li>
    </ul>
  </div>
</div>