import { Component, Input, ViewChild } from '@angular/core';
import { BcOffCanvasComponent } from '@bancolombia/design-system-web/bc-off-canvas';
import { FormBuilder, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BcAlertComponent } from '@bancolombia/design-system-web/bc-alert';
import { BcModalComponent } from '@bancolombia/design-system-web/bc-modal';
import { BcDialogService } from '@bancolombia/design-system-web/bc-services';
import { RequirementStatusEnum } from 'src/app/enums/reporte-estado.enum';
import { RequirementTypeEnum } from 'src/app/enums/reporte-novedad.enum';
import { AzureAdpPresenter } from 'src/app/presenters/azure-adp/azureAdp.presenter';
import { EventServiceService } from 'src/app/services/EventService/event-service.service';
import { ContratosService } from 'src/app/services/api-services/contratos-services/contratos.service';
import { UserGraphServices } from 'src/app/services/api-services/graph-services/user-graph.services';
import { RequirementService } from 'src/app/services/api-services/requirement-services/requirement.service';
import { EstadoServicesService } from 'src/app/services/estado-services/estado-service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { RequestUpdateRequirement } from 'src/app/services/api-services/requirement-services/requirement-services.interface';

interface Option {
  value: string;
  label: string;
}

@Component({
  selector: 'app-offcanvas-novedades-negociador',
  templateUrl: './offcanvas-novedades-negociador.component.html',
  styleUrls: ['./offcanvas-novedades-negociador.component.css']
})
export class OffcanvasNovedadesNegociadorComponent {

  public inputData!: string; // Usa el operador "!" para indicar que será inicializada
  selectedOption: string | null = null;
  administratorObservation: string = "";
  @Input() dataContractId: string | null = null;
  SaberSi: number | null = 10;



  @Input('novedad') novedad!: any;
  dataTable: any[] = [];
  public contract: any = [];
  estadoSeleccionado: string | null = null;
  @Input()
  idContrato!: string;
  @Input()
  tipoRequerimiento!: string;
  @Input()
  requirementId!: string;


  contractDetail: any = { selectedOption: null };
  @Input() contrato: any;
  public userGraphData: any;
  public userGrapFilter: any[] = [];
  public formMeetAdminInputs: any = {};
  public inputAdministratorObservation: string = "";
  public showModal = true;
  public valuesTemp: any = {};
  private azureAdpPresenter = new AzureAdpPresenter(this.userGraphServices)
  protected currentUser: any;


  @Input('contractDetail') _contract!: any;

  reportarNovedadEstado() {
    if (this.selectedOption) {
      this.estadoService.setEstadoSeleccionado(this.selectedOption);
    }
  }

  constructor(private estadoService: EstadoServicesService, private eventService: EventServiceService,
    private route: ActivatedRoute, private userGraphServices: UserGraphServices,
    private contratosService: ContratosService, private formBuilder: FormBuilder, private dialogService: BcDialogService,
    private requirementService: RequirementService, private http: HttpClient,) {
    this.estadoSeleccionado = this.estadoService.getEstadoSeleccionado();
    console.log('Estado seleccionado recibido:', this.estadoSeleccionado);
  }


  selectedContractId: string | null = null;

  // Método para manejar la selección del usuario en el accordion
  onAccordionItemSelected(event: MouseEvent) {
    const target = event.target as HTMLElement;
    // Obtén el atributo de datos (data-contract-id) del elemento del accordion seleccionado
    const contractId = target.getAttribute('data-contract-id');

    if (contractId) {
      // Asigna el idContract seleccionado a la propiedad selectedContractId
      this.selectedContractId = contractId;
    } else {
      // Maneja el caso en el que no se pueda obtener el idContract
      // this.alertAba("error", "No se pudo obtener el contrato seleccionado", "Error");
    }
  }


  closeOffCanvas() {

    // Reinicia los valores de tus variables
    this.selectedOption = null;
    this.contractDetail = { selectedOption: null };
    this.bcOffCanvasComponent.closeOffCanvas(null);
  }


  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const contractId = params.get('id');
      if (contractId !== null) {
        this.contratosService.getContractByIdContract(contractId).subscribe(data => {
          this.contractDetail = data.data;
        });
      }
    });

    this.contractDetail = {
      contractEntity: {
        idContract: this.idContrato, // Esto debe ser un valor válido
        // Otras propiedades del contrato
      },
      // Otras propiedades relacionadas con el contrato
    };


    this.contractDetail = {
      contractEntity: {
        idContract: this.idContrato, // Esto debe ser un valor válido
        // Otras propiedades del contrato
      },
    }
    this.route.paramMap.subscribe(params => {
      const contractId = params.get('id');
      if (contractId !== null) {
        this.contratosService.getContractByIdContract(contractId).subscribe(data => {
          this.contractDetail = data.data;
        });
      }
    });
    this.currentUser = JSON.parse(sessionStorage.getItem('userCurrentGraph') || '{}');

    console.log(this.novedad)
    /*this.contratosService.getAllContractByIdContrac(this.novedad.contractId.idContract).subscribe(
      {
        next: (success) => this.contract = success.data,
       // error: (error) => this.alertAba("error","No se pudo cargar los estados de los contratos","Error")
      }
    )
    */

  }
  onOptionSelected(event: any) {

    const value = event.target.value;
    if (value === "") {
      this.selectedOption = null; // Establece en null si no se selecciona ninguna opción
      // this.alertAba("error", "No se seleccionó ninguna opción", "Error");
      return; // Detiene la función aquí si no se selecciona ninguna opción.
    }
    this.selectedOption = value;
    this.contractDetail.selectedOption = value; // Asignar la opción al objeto contractDetail
    this.estadoService.setEstadoSeleccionado(value);
  }



  dateSelector = new FormControl();
  @Input() textButton!: string;


  @ViewChild('offCanvasComponent',
    {
      static: true,
    })
  /*
  *Muestra la configuracion del Canvas 
  */
  bcOffCanvasComponent: BcOffCanvasComponent | any;
  bcModalComponent: BcModalComponent | any;
  tipoOffcanvas = '';
  backdrop = '';
  alturaHorizontal = '';
  backdrops = [
    {
      label: 'Activo',
      value: '',
    },
    {
      label: 'Apagado',
      value: 'off',
    },
  ];
  alturasHorizontal = [
    {
      label: '90vh',
      value: '90vh',
    },
    {
      label: '50vh',
      value: '50vh',
    },
    {
      label: '40vh',
      value: '40vh',
    },
  ];
  /*
  *Abre el offcanvas.
  */
  openOffCanvas() {
    this.bcOffCanvasComponent.openOffCanvas();
  }

  /*
  *Cierra el offcanvas.
  */

  options: Option[] = [
    { value: 'Publicado', label: 'Publicado' },
    { value: 'Vencido', label: 'Vencido' },
    { value: 'Borrador', label: 'Borrador' },
    { value: 'Modificación del borrador', label: 'Modificación del borrador' },
    { value: 'Congelado', label: 'Congelado' },
    { value: 'Pendiente', label: 'Pendiente' },
  ];


  onNoveltySend() {
    console.log("ENTREEEE")
    if (!this.selectedOption) {
      this.alertAba("error", "No se pudo enviar, debido a que no se ingresó ninguna opción", "Error");
      return;
    }

    if (!this.inputData) {
      console.log("Hubo un error es nulo")
      this.alertAba("error", "No se pudo enviar, debido a que no se ingresaron observaciones", "Error");
      return;
    }

    const isAdminChangeRequest = this.tipoRequerimiento !== "REPORTE DE NOVEDAD DE ESTADO DE CONTRATO";
    const newStatus = isAdminChangeRequest ? 'EN PROCESO' : 'FINALIZADO';

    const updatedRequirement: RequestUpdateRequirement = {
      requirementId: this.requirementId,
      newStatus,
      comments: this.inputData
    }

    if (isAdminChangeRequest) updatedRequirement.newAdminEmail = this.formMeetAdminInputs.administatorEmail;

    this.requirementService.updateHistoryRequirement(updatedRequirement).subscribe({
      next: (res) => {
        if (res.responseCode != 200){
          this.alertAba("error", res.message, "Error");
          return;
        }

        this.alertAba("success", "La novedad de cambio de estado fue reportada", "Correcto")
        this.closeOffCanvas();
        window.location.reload();
      },
      error: (_err) => {
        this.alertAba("error", "La novedad de cambio de estado no se pudo procesar", "Error")
      },
    });
    
    // if (this.contractDetail && this.contractDetail.contractEntity) {

    //   const idContract = this.contractDetail.contractEntity.idContract;


    //   if (this.contractDetail && this.contractDetail.contractEntity && this.contractDetail.contractEntity.idContract) {
    //     const idContract = this.contractDetail.contractEntity.idContract;


    //     const saveData: manageRequirement = {
    //       idRequirement: this.requirementId,
    //       status: "CERRADO",
    //       newAdminEmail: this.formMeetAdminInputs.administatorEmail,
    //       observations: this.inputData,
    //     }

    //     if (this.tipoRequerimiento == "REPORTE NOVEDAD SIN ADMINISTRADOR") {
    //       saveData.status = "TRAMITADO"
    //     }




    //     /*const saveData: SaveRequirementModel = {
    //       emailReceptor: this.formMeetAdminInputs.administatorEmail,
    //       emailReques: this.currentUser.mail,
    //       idContract: this.contractDetail.contractEntity.idContract,
    //       observation: this.inputData,
    //       requirementType:requerimentType,
    //       requirementStatus: requirementStatus,
    //       initialDate : new Date()
    //     };
    //     */

    //     if (this.SaberSi == 3) {
    //       // Si no sabes quién es el administrador, no asignes fechas
    //       saveData.idRequirement = this.requirementId;
    //       saveData.status = "CERRADO";
    //       saveData.newAdminEmail = this.contractDetail?.admin?.email;
    //       saveData.observations = "SE RECHAZO EL CAMBIO DE ADMINSITRADOR";
    //     }

    //     console.log("Traje esta data del requerimiento : \n" + saveData.idRequirement);
    //     console.log("Traje esta data status : \n" + saveData.status);
    //     console.log("Traje esta data del nuevo admin : \n" + saveData.newAdminEmail);
    //     console.log("Traje esta data observations : \n" + saveData.observations);


    //     //this.http.put(environment.apiUrl + '/api/requirement/manageRequirement', this..requirementService.saveRequirement(saveData)).subscribe

    //     this.http.put(environment.apiUrl + '/requirement/manageRequirement', (saveData)).subscribe(
    //       {
    //         next: (response) => {
    //           this.alertAba("success", "La novedad de cambio de estado fue reportada", "Correcto")
    //           this.closeOffCanvas();
    //         },
    //         error: (error) => {
    //           this.alertAba("error", "La novedad de cambio de estado no se pudo procesar", "Error")
    //         },

    //       }

    //     )
    //   }
    //   else {
    //     // Maneja el caso en el que no estén definidos o no tengan un valor válido
    //     this.alertAba("error", "La propiedad idContract no está definida o es inválida", "Error");
    //   }

    // }

    // else {
    //   // Maneja el caso en el que no estén definidos o no tengan un valor válido
    //   this.alertAba("error", "La propiedad idContract no está definida o es inválida", "Error");
    // }

  }


  public get requirementTypeEnum(): typeof RequirementTypeEnum {
    return RequirementTypeEnum;
  }

  public get requirementStatusEnum(): typeof RequirementStatusEnum {
    return RequirementStatusEnum;
  }

  public selectSearchedUser(data: any) {
    this.formMeetAdminInputs = {
      administratorName: data.displayName,
      administatorEmail: data.mail,
      administratorObservation: "La novedad de cambio de Adminsitrador fue reportada. El operador de abastecimiento se encargará de actualizar el delegado del contrato.",
      adminsitratorInitialDate: null, // Asegúrate de usar el nombre correcto aquí
      adminsitratosFinalDate: null
    }

    this.userGrapFilter = [];
  }

  private alertAba(type: String, menssage: String, tittle: string): void {
    const dialogRef = this.dialogService.open(BcAlertComponent, {
      id: tittle,
      type: type,
      text: menssage,
      timeout: 7000,
    });

    dialogRef.onResult().subscribe(
      (closed) => { },
      (dismissed) => { },
      () => { }
    );
  }


}
