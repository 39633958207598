import { Injectable } from '@angular/core';
import { UserService } from '../api-services/user-services/user.service';
import { Module, UserRol } from 'src/app/models';
import { map } from 'rxjs';
import { LoggerService } from '../logger-service/logger.service';

/**
 * Servicio encargado de verificar los permisos del usuario para mostrar módulos en la aplicación.
 * Utiliza el UserService para obtener la información del usuario y sus permisos.
 */
@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  constructor(
    private readonly userService: UserService,
    private readonly logger: LoggerService
  ) { }

  /**
   * Verifica si el usuario tiene permisos para mostrar un módulo específico.
   * @param {string} moduleName - El nombre del módulo que se desea verificar.
   * @returns {boolean} true si el usuario tiene permisos para mostrar el módulo, false en caso contrario.
   */
  canShowModule(moduleName: string): boolean {
    // Obtener los datos del usuario desde el UserService
    const userData = this.userService.getCurrentUserData();
    const data = userData?.data as UserRol;
    // Verificar si el usuario tiene permisos para mostrar el módulo
    if (userData && data?.modules && data?.rol) {
      // Utiliza el método some() para verificar si algún módulo coincide con el moduleName y tiene el permiso "show".
      return data.modules.some((module: Module) => module.module === moduleName && module.permissions.includes('show'));
    }
    // Si no se encuentra información suficiente sobre los permisos del usuario, se retorna false.
    return false;
  }

  public isAvailableModule(user: UserRol, moduleName: string) {
    this.logger.log(`Available module ${moduleName} to user ${user.email}`)
    if (!user.modules && !user.rol) return false;
    return user.modules.some(
      (module) => module.module === moduleName && module.permissions.includes('show')
    );
  }
}
