import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.dev';


enum LogLevel {
  LOG = 'LOG',
  ERROR = 'ERROR',
  WARN = 'WARN',
  INFO = 'INFO'
}

interface LogEntry {
  level: LogLevel;
  message: string;
}

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  constructor() { }

  private logMessage({ level, message }: LogEntry) {
    const now = new Date();
    if (!environment.production) {
      const logText = `${level} - [${now.getHours()}:${now.getMinutes()}]: ${message}`;
      if (level === LogLevel.LOG || level === LogLevel.INFO) {
        console.log(logText);
        return;
      }
      if (level === LogLevel.ERROR){
        console.error(logText);
        return;
      }
      if (level === LogLevel.WARN){
        console.warn(logText);
        return;
      }
    }

  }

  log(message: string): void {
    this.logMessage({ level: LogLevel.LOG, message });
  }

  error(message: string): void {
    this.logMessage({ level: LogLevel.ERROR, message });
  }

  warn(message: string): void {
    this.logMessage({ level: LogLevel.WARN, message });
  }

  info(message: string): void {
    this.logMessage({ level: LogLevel.INFO, message });
  }
}
