import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { BcModalComponent } from '@bancolombia/design-system-web/bc-modal';
import { BcOffCanvasComponent } from '@bancolombia/design-system-web/bc-off-canvas';
import { EstadoServicesService } from 'src/app/services/estado-services/estado-service';
import { EventServiceService } from 'src/app/services/EventService/event-service.service';
import { ActivatedRoute } from '@angular/router';
import { ContratosService } from 'src/app/services/api-services/contratos-services/contratos.service';
import { RequirementService } from 'src/app/services/api-services/requirement-services/requirement.service';
import { RequirementTypeEnum } from 'src/app/enums/reporte-novedad.enum';
import { RequirementStatusEnum } from 'src/app/enums/reporte-estado.enum';
import { BcDialogService } from '@bancolombia/design-system-web/bc-services';
import { BcAlertComponent } from '@bancolombia/design-system-web/bc-alert';
import { UserGraphServices } from 'src/app/services/api-services/graph-services/user-graph.services';
import { AzureAdpPresenter } from 'src/app/presenters/azure-adp/azureAdp.presenter';
import { UserService } from 'src/app/services/api-services/user-services/user.service';



interface Option {
  value: string;
  label: string;
}

@Component({
  selector: 'app-reportar-cambio-estado',
  templateUrl: './reportar-cambio-estado.component.html',
  styleUrls: ['./reportar-cambio-estado.component.css']
})


export class ReportarCambioEstadoComponent implements OnInit{
  
  
  selectedOption: string | null = null;
  contractDetail: any = { selectedOption: null };
  @Input() contrato: any;  
  public userGraphData: any;
  public userGrapFilter: any[] = [];
  public formMeetAdminInputs: any= {};
  public inputAdministratorObservation: string = "";
  public showModal = true;
  public valuesTemp: any = {};
  private azureAdpPresenter = new AzureAdpPresenter(this.userGraphServices)
  protected currentUser: any;
  public inputData: any;


  @Input('contractDetail') _contract!: any;
  @Input ('userData') userData!: any;
  userDataAvailable = false;

  reportarNovedadEstado() {
    if (this.selectedOption) {
      this.estadoService.setEstadoSeleccionado(this.selectedOption);
    }
  }

  constructor(private estadoService: EstadoServicesService, private eventService: EventServiceService,
    private route: ActivatedRoute,private userGraphServices: UserGraphServices,
    private contratosService: ContratosService,private formBuilder: FormBuilder,private dialogService: BcDialogService,
    private requirementService: RequirementService,
    private userService: UserService) {}

  

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const contractId = params.get('id') ;
      if (contractId !== null) {
      this.contratosService.getContractByIdContract(contractId).subscribe(data => {
        this.contractDetail = data.data;       
      });
    }
    });

    this.userService.getUserDataSubject().subscribe((userData) => {
      this.userData = userData.data;
      this.userDataAvailable = true;
    });
    this.currentUser = JSON.parse(sessionStorage.getItem('userCurrentGraph') || '{}');

  }
  onOptionSelected(event: any) {

    const value = event.target.value; 
    if (value === "") {
    this.selectedOption = null; // Establece en null si no se selecciona ninguna opción
    this.alertAba("error", "No se seleccionó ninguna opción", "Error");
    return; // Detiene la función aquí si no se selecciona ninguna opción.
  }
    this.selectedOption = value;
    this.contractDetail.selectedOption = value; // Asignar la opción al objeto contractDetail
    this.estadoService.setEstadoSeleccionado(value);
  }

  
  
  dateSelector = new FormControl();
   @Input() textButton!: string;

   
  @ViewChild('offCanvasComponent',
    {
      static: true,
    })
    /*
    *Muestra la configuracion del Canvas 
    */    
  bcOffCanvasComponent: BcOffCanvasComponent | any;
  bcModalComponent: BcModalComponent | any;
  tipoOffcanvas = '';
  backdrop = '';
  alturaHorizontal = '';
  backdrops = [
    {
      label: 'Activo',
      value: '',
    },
    {
      label: 'Apagado',
      value: 'off',
    },
  ];
  alturasHorizontal = [
    {
      label: '90vh',
      value: '90vh',
    },
    {
      label: '50vh',
      value: '50vh',
    },
    {
      label: '40vh',
      value: '40vh',
    },
  ];
  /*
  *Abre el offcanvas.
  */
  openOffCanvas() {
    this.bcOffCanvasComponent.openOffCanvas();
  }

  /*
  *Cierra el offcanvas.
  */
  closeOffCanvas() {

    // Reinicia los valores de tus variables
  this.selectedOption = null;
  this.contractDetail = { selectedOption: null };
  this.inputData = null;
  this.bcOffCanvasComponent.closeOffCanvas(null);
  }
  
  options: Option[] = [
    { value: 'Publicado', label: 'Publicado' },
    { value: 'Vencido', label: 'Vencido' },
    { value: 'Borrador', label: 'Borrador' },
    { value: 'Modificación del borrador', label: 'Modificación del borrador' },
    { value: 'Congelado', label: 'Congelado' },
    { value: 'Pendiente', label: 'Pendiente' },
  ];


  public onNoveltySend(requerimentType : any,requirementStatus : any ){

    if (!this.selectedOption) {
      this.alertAba("error", "No se pudo enviar, debido a que no se ingresó ninguna opción", "Error");
      return; // Detiene la función aquí si no se selecciona ninguna opción.
    }

    if (!this.inputData) {
      this.alertAba("error", "No se pudo enviar, debido a que no se ingresaron observaciones", "Error");
      return; // Detiene la función aquí si no se ingresan observaciones.
    }
    const savedRequirement = {
      idContract: this.contractDetail.idContract,
      description: `El contrato debe marcarse como cerrado. Razón: ${this.inputData}`,
      requirementType: requerimentType
    }

    this.requirementService.createRequirement(savedRequirement).subscribe(
      {
        next: (_res) => {
          this.alertAba("success","La novedad de cambio de estado fue reportada","Correcto")
          this.closeOffCanvas();
          console.log("Este es el correo de la persona que reporto : " + this.currentUser.mail);
        },
        error: (error) => {
          this.alertAba("error","La novedad de cambio de estado no se pudo procesar", error)
        },
        
      }

    )
  }

  public selectSearchedUser(data: any) {
    this.formMeetAdminInputs = {
      administratorName: null,
      administatorEmail: null,
      administratorId:null ,
      administratorObservation: "Cambio de estado a generico",
      adminsitratorInitialDate: null, // Asegúrate de usar el nombre correcto aquí
      adminsitratosFinalDate: null 
    }

    this.userGrapFilter = [];
  }
  

  
  

  public get requirementTypeEnum() :typeof RequirementTypeEnum{
    return RequirementTypeEnum;
  }

  public get requirementStatusEnum() : typeof RequirementStatusEnum{
    return RequirementStatusEnum;
  }

  private alertAba(type: String, menssage: String, tittle: string): void {
    const dialogRef = this.dialogService.open(BcAlertComponent, {
      id: tittle,
      type: type,
      text: menssage,
      timeout: 7000,
    });

    dialogRef.onResult().subscribe(
      (closed) => {},
      (dismissed) => {},
      () => {}
    );
  }

  

  showSelectedValue(value: string) {
  }
  selector = new FormControl();
  formatType = 'simple';
  values = [
    {
      label: 'Gokú',
      value: {
        human: true,
      },
    },
    {
      label: 'Vegeta',
      value: 'Resplandor Final',
      icon: 'cloud'
    },
    'Krilin',
  ];

  
  
  
}
