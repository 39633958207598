<bc-off-canvas #offCanvasComponent [tipoOffcanvas]="'vertical'" [backdrop]="''">
    <div offCanvasContent>
      <h1 class="bc-text-center">Gestión de requerimientos</h1>
      <br>
      <div class="Diseño-items">
        <p>
          Recuerda revisar que la solicitud cumpla con los requisitos establecidos para su gestión:
        </p>
        <br>
      <h5>Tipo de requerimiento</h5>
      <p>{{tipoRequerimiento}}</p>
      <br>
      <h5>ID Contrato</h5>
      <p>{{idContrato}}</p>
      <br>
      <h5>Recuerda</h5>
      <p>Antes de cerrar el requerimiento revisa que la información se encuentre actualizada en Ariba y en Conectados en
        caso de ser necesario.</p>
      <br>

      </div>
      <!--Inputs-->
      

      <div *ngIf="tipoRequerimiento == 'REPORTE PARA ACTUALIZACION EN ARIBA' ">
        <div class="bc-container">
          <div class="bc-row">
            <div class="bc-col-6">
              <label for="selectInput">
                <div class="Diseño-items">
                  <p>
                   Selecciona una opción:
                  </p> 
                </div>
              </label>
            </div>
            <div class="bc-col-6 ">
              <select id="selectInput" class="custom-select" (change)="onOptionSelected($event)">
                <option value=""></option>
                <option value="CERRADO">Cerrado</option>
              </select>
            </div>
          </div>
        </div> 
  
      </div>
  
  
      <div *ngIf="tipoRequerimiento !== 'REPORTE PARA ACTUALIZACION EN ARIBA' ">
        <div class="bc-container">
          <div class="bc-row">
            <div class="bc-col-6">
              <label for="selectInput">
                <div class="Diseño-items">
                  <p>
                   Selecciona una opción:
                  </p> 
                </div>
              </label>
            </div>
            <div class="bc-col-6 ">
              <select id="selectInput" class="custom-select" (change)="onOptionSelected($event)">
                <option value=""></option>
                <option value="FINALIZADO">Finalizado</option>
              </select>
            </div>
          </div>
        </div> 
  
      </div>
      
      
    
    <!-- Observaciones -->
      <br>
      <div class="bc-margin-top-3">
        <bc-form-field class="bc-margin-top-5">
          <input id="03" bc-input aria-label="text-area" [(ngModel)]="inputData"
            type="text" name="administratorObservation" autocomplete="off">
          <label for="03">
            <h5>
              Observaciones
            </h5></label>
        </bc-form-field>
      </div>      
      
      <br>
      <br>
      <div class="bc-container">
        <button class="bc-button-primary bc-button-default" (click)="onNoveltySend()">
          Enviar
        </button>
      </div>
      <br>
      <!-- <br>
      <button class="bc-button-primary bc-button-primary"
      (click)="onNoveltySend(requirementTypeEnum.REPORTE_ACEPTADO_NEGOCIADOR, requirementStatusEnum.Pendiente)">
      Reportar Novedad
    </button> -->
    </div>
  </bc-off-canvas>
  
  <button class="bc-button-primary bc-button-default" (click)="openOffCanvas()">
    {{textButton}}
  </button>