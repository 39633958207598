<bc-off-canvas #offCanvasComponent [tipoOffcanvas]="'vertical'" [backdrop]="''">
  <div offCanvasContent>
    <h2 class="bc-text-center">Gestión de requerimientos</h2>
    <br>
    <div class="Diseño-items">
      <p>
        Recuerda revisar que la solicitud cumpla con los requisitos establecidos para su gestión:
      </p>
    </div>
    <br>
    <!--Inputs-->
    <div class="Diseño-items">
      <h5>Tipo de requerimiento</h5>
      <p>{{tipoRequerimiento}}</p>
      <br>
      <h5>ID Contrato</h5>
      <p>{{idContrato}}</p>
      <br>
      <h5>Recuerda</h5>
      <p>Antes de cerrar el requerimiento revisa que la información se encuentre actualizada en Ariba y en Conectados en
        caso de ser necesario,</p>
    </div>



    <br>
    <div *ngIf="tipoRequerimiento == 'REPORTE PARA ACTUALIZACION EN ARIBA' ">
      <div class="bc-container">
        <div class="bc-row">
          <div class="bc-col-6">
            <label for="selectInput">
              <div class="Diseño-items">
                <h5>
                  Selecciona una opción:
                </h5>
              </div>
            </label>
          </div>
          <div class="bc-col-6 select-container">
            <select id="selectInput" class="custom-select" (change)="onOptionSelected($event)">
              <option value=""></option>
              <option value="CERRADO">Cerrado</option>
            </select>
          </div>
        </div>
      </div>

    </div>

    <div *ngIf="tipoRequerimiento == 'REPORTE DE NOVEDAD DE ESTADO DE CONTRATO' ">
      <div class="bc-container">
        <div class="bc-row">
          <div class="bc-col-6">

            <label for="selectInput">
              <div class="Diseño-items">
                <h5>
                  Selecciona una opción:
                </h5>
              </div>

            </label>
          </div>
          <div class="bc-col-6 select-container">
            <select id="selectInput" class="custom-select" (change)="onOptionSelected($event)">
              <option value=""></option>
              <option value="CERRADO">Cerrado</option>
            </select>
          </div>
        </div>
      </div>

    </div>


    <div
      *ngIf="tipoRequerimiento !== 'REPORTE PARA ACTUALIZACION EN ARIBA' && tipoRequerimiento !== 'REPORTE DE NOVEDAD DE ESTADO DE CONTRATO' ">
      <div class="bc-container">
        <div class="bc-row">
          <div class="bc-col-6">
            <div class="Diseño-items">
              <label for="selectInput">
                <div class="Diseño-items">
                  <h5>
                    Selecciona una opción:
                  </h5>
                </div>
              </label>
            </div>
          </div>
          <div class="bc-col-6 select-container">
            <select id="selectInput" class="custom-select" (change)="onOptionSelected($event)">
              <option value=""></option>
              <option value="TRAMITADO">Tramitado</option>
            </select>
          </div>
        </div>
      </div>

      <!-- <div class="bc-container">
        <div class="bc-row">
            <div class="bc-col-6">
                <label for="selectInput">Selecciona una opción:</label>
              </div>
              <div *ngIf="">
  
              </div>
              <div class="bc-col-6">
                <select id="selectInput" (change)="onOptionSelected($event)" [(ngModel)]="selectedOption">
                  <option value=""></option>
                  <option value = "TRAMITADO">Tramitado</option>
                </select>
              </div>
              
        </div>
    </div>    -->

    </div>



    <!-- Observaciones -->
    <br>
    <div class="bc-margin-top-2">
      <bc-form-field>
        <br>
        <input id="03" bc-input aria-label="text-area" [(ngModel)]="inputData" type="text"
          name="administratorObservation" autocomplete="off">
        <label for="03">
          <div class="Diseño-items">
            <h5>
              Observaciones
            </h5>
          </div>
        </label>
      </bc-form-field>
    </div>
    <!-- Correo -->

    <!-- <div *ngIf="tipoRequerimiento == 'REPORTE PARA ACTUALIZACION EN ARIBA'" class="bc-container">
      <bc-form-field>
        <input id="02" bc-input aria-label="text-area" type="text" [(ngModel)]="novedad.receptorId.usuarioRed"
          type="text" name="administatorEmail" required>
        <label for="02">Correo confirmación del administrador*</label>
      </bc-form-field>
    </div> -->
    <br>
    <div
      *ngIf="tipoRequerimiento !== 'REPORTE DE NOVEDAD DE ESTADO DE CONTRATO'"
      class="bc-container">
      <bc-form-field>
        <br>
        <input id="02" bc-input aria-label="text-area" type="text" [(ngModel)]="formMeetAdminInputs.administatorEmail"
          type="text" name="administatorEmail" required>
        <label for="02">
          <div class="Diseño-items">
            <h5>
              Correo confirmación del administrador*
            </h5>
          </div>
        </label>
      </bc-form-field>
    </div>





    <br>
    <br>
    <div class="bc-container">
      <button class="bc-button-primary bc-button-default"
        (click)="onNoveltySend()">
        Enviar
      </button>
    </div>
<!-- 
    <div *ngIf="tipoRequerimiento == 'REPORTE DE NOVEDAD DE ESTADO DE CONTRATO' " class="bc-container">
      <button class="bc-button-primary bc-button-default"
        (click)="onNoveltySend(requirementTypeEnum.REPORTE_NOVEDAD_CON_ADMINISTRADOR, requirementStatusEnum.Cerrado)">
        Enviar
      </button>
    </div>
    <div
      *ngIf="tipoRequerimiento !== 'REPORTE PARA ACTUALIZACION EN ARIBA' && tipoRequerimiento !== 'REPORTE DE NOVEDAD DE ESTADO DE CONTRATO'"
      class="bc-container">
      <button class="bc-button-primary bc-button-default"
        (click)="onNoveltySend(requirementTypeEnum.REPORTE_NOVEDAD_CON_ADMINISTRADOR, requirementStatusEnum.Tramitado)">
        Enviar
      </button> -->
    <!-- </div> -->


    <br>
    <!-- <br>
    <button class="bc-button-primary bc-button-primary"
    (click)="onNoveltySend(requirementTypeEnum.REPORTE_ACEPTADO_NEGOCIADOR, requirementStatusEnum.Pendiente)">
    Reportar Novedad
  </button> -->
  </div>
</bc-off-canvas>

<button class="bc-button-primary bc-button-default" (click)="openOffCanvas()">
  {{textButton}}
</button>