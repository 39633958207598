<div class="bc-content bc-container-solid bc-bg-light ">
    <div class="Encabezado">
      <img src="../../../assets/images/Header-Novedades.png">
    </div>
    <bc-page-header [date]='fecha' [bgWhite]="false" [className]="'bc-bg-light'">
    </bc-page-header>

    <div *ngIf="dataLoaded == false">
      <load-aba-component></load-aba-component>
    </div>
  </div>
  
    <div class="bc-text-center">
      <div>
        <p>
          En esta página encuentras las las novedades que has reportado. 
          <br>
          ¿Quieres conocer más información? Ingresa a la notificación.
        </p>
      </div>
    </div>
  
    <!-- Agrupación de Pendientes -->
    <div class=" bc-content bc-container-solid bc-padding-4  bc-bg-light">
    <br>
    <ng-container *ngIf="requirement.length > 0; else noNovedades">
      <h3>Notificaciones pendientes</h3>
      <br>
      <bc-accordions-group [className]="'bc-margin-top-2'">
        <ng-container *ngFor="let novedad of requirement">
          <ng-container *ngIf="novedad.lastStatus !== 'FINALIZADO' &&  novedad.lastStatus !== 'RECHAZADO'">
            <bc-accordion type="column" class="bc-mb-4">
              <bc-accordion-header title="ID: {{novedad.requirement.id | slice:0:5}}" [columns]="columnsForRequirement(novedad)"></bc-accordion-header>
              <bc-accordion-content>
                <app-novedad-accordion-content [novedad]="novedad"></app-novedad-accordion-content>
              </bc-accordion-content>
            </bc-accordion>
          </ng-container>
        </ng-container>
      </bc-accordions-group>
    </ng-container>
  
    <!-- Agrupación de Completados -->

    <br>
    <ng-container *ngIf="requirement.length > 0; else noNovedades">
        <h3>Notificaciones finalizadas</h3>
        <br>
        <bc-accordions-group [className]="'bc-margin-top-2'">
          <ng-container *ngFor="let novedad of requirement">
            <ng-container *ngIf="novedad.lastStatus === 'FINALIZADO' ||  novedad.lastStatus === 'RECHAZADO'">
              <bc-accordion type="column" class="bc-mb-4">
                <bc-accordion-header title="ID: {{novedad.requirement.id | slice:0:5}}" [columns]="columnsForRequirement(novedad)"></bc-accordion-header>
                <bc-accordion-content>
                  <app-novedad-accordion-content [novedad]="novedad"></app-novedad-accordion-content>
                </bc-accordion-content>
              </bc-accordion>
            </ng-container>
          </ng-container>
        </bc-accordions-group>
      </ng-container>
  
    <!-- Mensaje si no hay Notificaciones Pendientes o Completadas -->
    <ng-template #noNovedades>
      <div class="no-novedades-message-novedades">
        <img src="../../../assets/images/il-sv-process.svg" alt="No Novedades">
        <p>¡Oh no! Parece que no tienes novedades por el momento. Sigue atento para futuras actualizaciones.</p>
      </div>
    </ng-template>
  </div>
  