import { Component, Input } from '@angular/core';
import { RequirementService } from 'src/app/services/api-services/requirement-services/requirement.service';

@Component({
  selector: 'app-novedad-accordion-content',
  templateUrl: './novedad-accordion-content.component.html',
  styleUrls: ['./novedad-accordion-content.component.css']
})
export class NovedadAccordionContentComponent {

  @Input('novedad') novedad!: any; 
  dataTable: any[] = [];
  @Input('contract') contract!: any; 
  @Input('requirement') requirement!: any;
  

  constructor(private readonly requirementService: RequirementService){}

  ngOnInit(): void {

  }
}
