import { Component, Input } from '@angular/core';
import { BcAlertComponent } from '@bancolombia/design-system-web/bc-alert';
import { BcDialogService } from '@bancolombia/design-system-web/bc-services';
import { ContratosService } from 'src/app/services/api-services/contratos-services/contratos.service';
import { RequirementService } from 'src/app/services/api-services/requirement-services/requierement.service';
import { EstadoServicesService } from 'src/app/services/estado-services/estado-service';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/api-services/user-services/user.service';
import { RequirementStatusEnum } from 'src/app/enums/reporte-estado.enum';
import { RequirementTypeEnum } from 'src/app/enums/reporte-novedad.enum';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-novedad-negociador-accordion',
  templateUrl: './novedad-negociador-accordion.component.html',
  styleUrls: ['./novedad-negociador-accordion.component.css']
})
export class NovedadNegociadorAccordionComponent {


  
  @Input('novedad') novedad!: any; 
  dataTable: any[] = [];
  public contract: any = [];
  estadoSeleccionado: string | null = null;
  selectedOption: string | null = null;
  contractDetail: any = { selectedOption: null };

  constructor(
    private dialogService: BcDialogService,
    private contratosService: ContratosService,
    private estadoService: EstadoServicesService,
    private userService: UserService,
    private route: ActivatedRoute
    ){
      this.estadoSeleccionado = this.estadoService.getEstadoSeleccionado();
    console.log('Estado seleccionado recibido:', this.estadoSeleccionado);
    
  }

  reportarNovedadEstado() {
    if (this.selectedOption) {
      this.estadoService.setEstadoSeleccionado(this.selectedOption);
    }
  }

  onOptionSelected(event: any) {

    const value = event.target.value; 
    if (value === "") {
    this.selectedOption = null; // Establece en null si no se selecciona ninguna opción
    this.alertAba("error", "No se seleccionó ninguna opción", "Error");
    return; // Detiene la función aquí si no se selecciona ninguna opción.
  }
    this.selectedOption = value;
    this.contractDetail.selectedOption = value; // Asignar la opción al objeto contractDetail
    this.estadoService.setEstadoSeleccionado(value);
  }

  ngOnInit(): void {

    this.route.paramMap.subscribe(params => {
      const contractId = params.get('id') ;
      if (contractId !== null) {
      this.contratosService.getContractByIdContract(contractId).subscribe(data => {
        this.contractDetail = data.data;       
      });
    }
    });
    this.userData = this.userService.getCurrentUserData();


  }

  private alertAba(type: String, menssage: String, tittle: string): void {
    const dialogRef = this.dialogService.open(BcAlertComponent, {
      id: tittle,
      type: type,
      text: menssage,
      timeout: 7000,
    });

    dialogRef.onResult().subscribe(
      () => {},
      () => {},
      () => {}
    );
  }

  

  //Variables
  currentPageIndex: number = 0;
  itemsPerPage: number = 10;
  userData: any;
  userDataAvailable = false;
  selectedContract: any;
  showDescription: boolean = false;
  @Input('data') _pages!: any;
  totalRegisters: any;
  perpage: any = 10;
  permultiple: string = "10";



  showDetails(contract: any) {
    console.log("Contract recibido:", contract); // Verifica si el contrato se recibe correctamente.
  
    if (contract && contract.contractEstatusEntity) {
      this.selectedContract = contract; // Asignar el contrato seleccionado a la variable
    } else {
      console.log("Error: Contract o contractEstatusEntity es nulo o indefinido.");
    }
  }

  //Rechazar

  //Variables
  public userGraphData: any;
  public userGrapFilter: any[] = [];
  public formMeetAdminInputs: any = {};
  public inputAdministratorObservation: string = "";
  public showModal = true;
  public valuesTemp: any = {};

  dateSelector = new FormControl();
  @Input() textButton!: string;
  @Input() contrato: any;
  

  public get requirementTypeEnum(): typeof RequirementTypeEnum {
    return RequirementTypeEnum;
  }

  public get requirementStatusEnum(): typeof RequirementStatusEnum {

    return RequirementStatusEnum;

  }


  public inputData: any;
  protected currentUser: any;
  SaberSi: number | null = 10;

}
