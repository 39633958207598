import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccordionColumn } from '@bancolombia/design-system-web/bc-accordion';
import { BcAlertComponent } from '@bancolombia/design-system-web/bc-alert';
import { BcDialogService } from '@bancolombia/design-system-web/bc-services';
import { formatearFecha } from 'src/app/components/utils/formato-fecha.componente';
import { Contrato } from 'src/app/models/contratos.interface';
import { NovedadEstado } from 'src/app/models/novedad.interface';
import { ContratosService } from 'src/app/services/api-services/contratos-services/contratos.service';
import { NovedadesService } from 'src/app/services/api-services/novedades-services/novedades.service';
import { RequirementService } from 'src/app/services/api-services/requirement-services/requirement.service';
import { UserService } from 'src/app/services/api-services/user-services/user.service';
import { AppStateService } from 'src/app/services/commo-service/reload-services';

@Component({
  selector: 'app-mis-novedades',
  templateUrl: './mis-novedades.component.html',
  styleUrls: ['./mis-novedades.component.css']
})
export class MisNovedadesComponent {
  userData : any = {};
  tittle = "En esta página encuentras las las novedades que has reportado.";
  fecha: any;
  protected currentUser: any;
  public requirement: any[] = [];
  public contratos: Contrato[] = [];
  filteredRequirements: any[] = [];

  ngOnInit() {
    this.mostrarHora();
    this.currentUser = JSON.parse(sessionStorage.getItem('userCurrentGraph') || '{}');
    this.userData = this.userService.getCurrentUserData();
    this.loadData();
    //this.loadContratos();
    this.searchTerm = ''; // Configura el valor inicial como una cadena vacía
    this.onSearch();

    
  }
  
  columnsForRequirement(requirement: any): Array<AccordionColumn> {
    return [
      
      {value : "CONTRATO " + requirement.requirement.contract.idContract},
      {value : requirement.requirement.requirementType},
      {value: requirement.lastStatus },
    ];
  }

  
  
  

  /*private loadContratos() {
    // Aquí deberás cargar la información de los contratos
    this.contratosService.getContratos().subscribe(data => {
      this.contratos = data; // Asignar los contratos a la propiedad
      console.log("Traje esto contratos ", data)
    });

  }*/

  contractDetail: any = {};
  contractChild: any = {};
  contractMaster: any = {};
  constructor(
    private novedadesServices: NovedadesService,
    private requirementService: RequirementService,
    private dialogService: BcDialogService,
    private route: ActivatedRoute,
    private contratosService: ContratosService,
    private userService: UserService,
    private appStateService: AppStateService
    ){
    this.novedadesServices.searchNovedadesByAdmin()
  }



  /**
  Muestra la hora actual en la página y actualiza el valor cada segundo.
  @returns {void}
  */
  mostrarHora() {
    this.fecha = formatearFecha(new Date());
    setInterval(() => {
      this.fecha = formatearFecha(new Date());
    }, 1000);
  }

  /**
  * Returns an array of `NovedadEstado` objects obtained from the `NovedadesService`.
  * @returns {NovedadEstado[]} An array of `NovedadEstado` objects.
  */
  getNovedades(): NovedadEstado[]{
    return this.novedadesServices.resultados
    console.log("las novedades son " + this.novedadesServices.resultados.toString());
  }

  getContrato(): Contrato [] {
    return this.contratosService.resultadosContratos
  }


  /**
  Obtiene las columnas para un panel de acordeón para una novedad.
  @param {NovedadEstado} novedadEstado - La novedad y su estado actual.
  @returns {Array<AccordionColumn>} - Las columnas del panel de acordeón.
  */
  getAccordionColumns(novedadEstado: NovedadEstado) : Array<AccordionColumn>{
    const cols:Array<AccordionColumn> = [
      //{value: `Contrato ${novedadEstado.contrato.idContrato}`, alignLeft: true},
      //{value: `${novedadEstado.tipoNovedad.nombre}`},
      //{value: `${novedadEstado.historialEstados[0].estado.nombre}`},
    ]

    return cols
  }
  
  private alertAba(type: String, menssage: String, tittle: string): void {
    const dialogRef = this.dialogService.open(BcAlertComponent, {
      id: tittle,
      type: type,
      text: menssage,
      timeout: 7000,
    });

    dialogRef.onResult().subscribe(
      (closed) => {},
      (dismissed) => {},
      () => {}
    );
  }



  arrResultNull: any[] = [];

  printData($event: any) {
    throw new Error('Method not implemented.');
  }
  
  // Accordion
  columns: Array<AccordionColumn> = [
    { value: 'Contrato CW345', alignLeft: true },
    { value: 'Cambio de estado' },
    { value: 'Completado' },
  ];

  dataTable: any[] = [];



  loadContractData(contractId: string) {
    this.resetData();
    console.log("carga data");
    this.loadContractDetail(contractId);
    this.loadChildContracts(contractId);
  }

  loadContractDetail(contractId: string) {
    this.contratosService.getContractByIdContract(contractId).subscribe(data => {
      this.contractDetail = data.data;
      let idContractMaster = this.contractDetail.contractEntity.idContractMaster!
      if (idContractMaster) {
        this.contratosService.getContractByIdContract(idContractMaster).subscribe(master => {
          this.contractMaster = master.data;
        });
      }
    });
  }

  loadChildContracts(contractId: string) {
    this.contratosService.getChildsContractsByIdContract(contractId).subscribe(data => {
      this.contractChild = data.data;
    });
  }

  resetData() {
    this.contractDetail = {};
    this.contractChild = {};
    this.contractMaster = {};
  }

  // Variables Usuario
  userDataAvailable = false;

  /**
   * Constructor del componente DashboardComponent.
   * @param {UserService} userService - Servicio para obtener los datos del usuario autenticado.
 

  /**
   * Método del ciclo de vida de Angular OnInit.
   * Se ejecuta una vez que el componente ha sido inicializado.
   * Aquí se obtienen los datos del usuario autenticado desde el UserService y se almacenan en la variable userData.
   */



  //
 public novedad: any[] = [];
 dataLoaded: boolean = false;
 requirementnov: any[] = [];

 changeStateRequierement = new Object();
 changeStateContact = new Object();

 changeStateRequirement = {
   idRequiremet: 'requirement.id',
   status: 'requirement.contract.status'
 }

 changeStateContract = {
  idContract: 'contracto.IdContract'
 }

 private loadData() {
  const requirementParam: any = {
    email: this.userData.data.email,
    type: "APPLICANT"
  }

  this.requirementService.findRequirementsForReceptorOrApplicant(requirementParam).subscribe({
    next: (response) => {
      console.log(response);
      this.requirement = response;
      this.dataLoaded = true;
    },
    error: (err) => console.error(err),
   })
}


 searchTerm: string = 'CW';

 onSearch() {
  if (this.searchTerm) {
    this.filteredRequirements = this.requirement.filter((req) =>
      req.requirement.contract.idContract.includes(this.searchTerm)
    );
  } else {
    this.filteredRequirements = this.requirement; // Muestra todos los datos
  }
}




 

}
